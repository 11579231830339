import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"
import tw from "tailwind.macro"

import Layout from "../components/layout"
import SEO from "components/seo"
import SubBanner from "../components/SubBanner"
import CTA from "../components/CTA"
import Link from "../components/Link"

const ProductItem = styled.div`
  ${tw`w-full mb-44 md:mb-38 md:w-1/2 md:px-30 lg:px-34 xl:w-1/3 xl:mb-40`}

  :last-child {
    ${tw`mb-0`}
  }

  :nth-last-child(-n + 2) {
    ${tw`md:mb-0`}
  }

  :nth-last-child(-n + 3) {
    ${tw`lg:mb-0`}
  }

  .product-item-inner {
    ${tw`mx-auto relative`}
    max-width: 303px;

    @media (min-width: 768px) {
      min-width: 244px;
    }

    @media (min-width: 1024px) {
      min-width: 320px;
    }

    @media (min-width: 1280px) {
      min-width: 325px;
    }
  }

  .gatsby-image-wrapper {
    border-radius: 60px 0;
    min-width: 100%;
    max-width: 100%;
    height: auto !important;
    padding-bottom: 100%;
    transition: all 200ms ease-out;
  }

  h4 {
    ${tw`font-fb text-xl text-black-dark mt-10`}
    line-height: 44px;
  }

  a {
    ${tw`absolute h-full w-full top-0 left-0`}
    z-index: 99;

    &:hover {
      + .gatsby-image-wrapper {
        box-shadow: -4px -4px 20px 0px #888888;
      }
    }
  }
`

const Products = ({ data, location }) => {
  const { products, page } = data
  return (
    <Layout path={location.pathname}>
      <SEO data={page.edges[0].node.seo} />
      <SubBanner
        mainTitle={page.edges[0].node.acfSubPageBanner.sloganTitle}
        subTitle={page.edges[0].node.acfSubPageBanner.sloganText}
        image={
          page.edges[0].node.acfSubPageBanner.subPageBannerImage.localFile
            .childImageSharp.fluid
        }
      />
      <div className="max-w-xl mx-auto px-18 md:px-20 section-gap-sm xl:section-gap-xl">
        <div className="flex flex-wrap xxl:-mx-26">
          {products.edges.map(item => (
            <ProductItem key={item.node.id}>
              <div className="product-item-inner">
              <Link to={`/products/${item.node.slug}`} />
                <Img
                  fixed={
                    item.node.acfCategory.categoryImage.localFile
                      .childImageSharp.fixed
                  }
                  objectFit="cover"
                  objectPosition="50% 50%"
                  alt=""
                />
                <h4>{item.node.name}</h4>
                
              </div>
            </ProductItem>
          ))}
        </div>
      </div>
      <CTA
        text={page.edges[0].node.acfCta.ctaText}
        buttonOne={page.edges[0].node.acfCta.ctaLink1}
        buttonTwo={page.edges[0].node.acfCta.ctaLink2}
      />
    </Layout>
  )
}

export const query = graphql`
  query Products {
    products: allWpProductCategory(sort: { order: ASC, fields: databaseId }) {
      edges {
        node {
          id
          name
          slug
          uri
          acfCategory {
            categoryImage {
              localFile {
                childImageSharp {
                  fixed(width: 325, height: 325, quality: 90) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    page: allWpPage(filter: { slug: { eq: "products" } }) {
      edges {
        node {
          acfSubPageBanner {
            subPageBannerImage {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            sloganText: subPageBannerSloganText
            sloganTitle: subPageBannerSloganTitle
          }
          acfCta {
            ctaShow
            ctaText
            ctaLink1 {
              target
              title
              url
            }
            ctaLink2 {
              target
              title
              url
            }
          }
          ...SEO
        }
      }
    }
  }
`

export default Products
